@import '../variables.scss';

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    padding: 40px 100px;

    @include breakpoint(md) {
        flex-direction: row;
        justify-content: space-between;
    }

    &__title {
        display: flex;
        font-size: 65px;
        font-weight: 600;
        color: $primary;
        transition: 0.4s;
    }

    &__navigation {
        display: none;

        @include breakpoint(md) {
            display: flex;
        }

        &__navbar {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &__link {
                position: relative;
                list-style: none;
                font-size: 25px;
                margin: 0 15px;
                color: $light;
                transition: 0.3s ease all;

                &:hover {
                     color: $lartofto;
                     cursor: pointer;
                }
            }
        }
    }
}

.sticky {
    background-color: $lartofto;
    padding: 10px 30px;
    

    .header__title {
        font-size: 45px;
        color: $light;
    }

    .header__navigation__navbar__link {
        &:hover {
            color: $dark;
        }
    }
}

// &__link {
//     text-decoration: none;
//     color: inherit;

//      &__btn {
//         color: #b8e0fc;
//         background: none;
//         border: 2px solid;
//         font-size: 14px;
//         margin: 0.5em;
//         padding: 1em 2em;
//         border-radius: 2px;
//         transition: 0.4s;
//         cursor: pointer;

//         &:hover {
//             color: #152124;
//             box-shadow: inset 0 0 0 2em #b8e0fc;
//         }
//     }


// }