@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
}

body {
    font-family: 'Poppins', sans-serif;
    color: black;
}

