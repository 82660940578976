@import "../variables.scss";

.slide3 {
    display: flex;
    flex-direction: column;
    background-color: rgba($color: black, $alpha: 0.3);
    min-height: 30vh;
    padding: 100px;
    text-align: center;
    color: $light;

    &__title {
        padding: 30px;
    }

    &__cards {
        display: grid;
        justify-items: center;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 1rem;

        &__card {
            display: flex;
            justify-content: center;
            min-width: 230px;
            padding: 15px;

            background-color: transparent; //rgba(0, 0, 0, 0.555)
            border-color: $light;
            border-width: 2px;
            border-radius: 15px;
            border-style: solid;

            cursor: pointer;
            transition: 0.3s ease all;

            &:hover {
                transform: scale(1.05);
                background-color: rgba($lartofto, 0.555);
            }

            &:nth-child(0) {
                @include breakpointCard() {
                    grid-column: 1 / 2;
                    grid-row: 1 / 2;
                }
            }
            &:nth-child(1) {
                @include breakpointCard() {
                    grid-column: 2 / 3;
                    grid-row: 1 / 2;
                }
            }
            &:nth-child(2) {
                @include breakpointCard() {
                    grid-column: 1 / 2;
                    grid-row: 2 / 3;
                }
            }
            &:nth-child(3) {
                @include breakpointCard() {
                    grid-column: 2 / 3;
                    grid-row: 2 / 3;
                }
            }
        }
    }

    &__modals {
        display: none;
        flex-direction: column;
        position: fixed;
        width: 450px;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background-color: $light;
        

        border-color: $dark;
        border-width: 2px;
        border-radius: 15px;
        border-style: solid;

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 10px;

            &__title {
                color: $dark;
            }

            &__close {
                color: black;
                font-size: 32px;
                cursor: pointer;

                transition: 0.15s ease transform;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        &__link {
            color: $lartofto;
            font-size: 22px;
        }

        &__descr {
            text-align: left;
            margin: 10px;
            color: black;
        }
    }
}
