$primary: #b8e0fc;
$dark: #152124;
$lartofto: #325064;
$light: #fafafa;

@mixin breakpoint($point) {
    @if $point == md {
        @media (min-width: 1150px) {
            @content;
        }
    }

    @if $point == lh {
        @media (min-width: 720px) {
            @content;
        }
    }
}

@mixin breakpointCard() {
    @media (max-width: 1262px) and (min-width: 982px) {
        @content;
    }
}