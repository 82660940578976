@import '../variables.scss';

.slide2 {
    display: flex;
    flex-direction: column;
    background-color: $light;
    width: 100%;
    padding: 20px 10px;
    align-items: left;
    justify-content: center;

    @include breakpoint(md) {
        padding: 20px 20vw;
    }
}