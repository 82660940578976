@import '../variables.scss';

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 7vh;
    background-color: $lartofto;
    padding: 5px;

    &__text {
        &__link {
            text-decoration: underline;
            cursor: pointer;
            color: black;
            transition: 0.3s ease all;

            &:hover {
                color: $primary;
            }
        }
    }

    &__modal {
        display: flex;
        flex-direction: column;

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 10px;

            &__close {
                font-size: 32px;
                cursor: pointer;
            }
        }
    }

    // &__modal {
    //     margin: 15vw;
    // }
}