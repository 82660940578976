@import "../variables.scss";

.slide1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    &__bg {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-image: url(../../assets/drapeau.png);
        background-size: cover;
        //background-position: -25vw -25vh;
        background-attachment: fixed;
        transform: scale(2);
        z-index: -4;
    }

  &__box {
    //display: none;
    min-width: 283px;
    max-width: 590px;
    margin-left: 5vw;
    margin-right: 5vw;
    padding: 10px;

    background-color: rgba(0, 0, 0, 0.76);
    color: $light;
    border-radius: 15px;
    text-align: left;

    &__title {
      color: $primary;
    }
  }
}
